import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { truncate, stripHTML, getCategoryTypeUrl } from '@/utils'
import { Link } from 'gatsby'
import { useHoverDirty } from 'react-use'
import dayjs from 'dayjs'

export interface PostCardProps {
  title?: string
  excerpt?: string
  date?: string
  image?: any
  slug?: string
  link?: string
  category?: PostCategory
  children?: React.ReactChild
  className?: string
}

interface PostCategory {
  name: string
  slug: string
  type: 'series' | 'topic'
}

interface PostContext extends PostCardProps {
  hovered: boolean
  setHovered: (hovered: boolean) => void
}
const PostContext = createContext<PostContext>({
  hovered: false,
  setHovered: () => {},
})

function Image({
  alt = '',
  className,
  wrapperClassName,
  isGatsbyImage = true,
  triggerHover = true,
}: {
  alt?: string
  className?: string
  wrapperClassName?: string
  isGatsbyImage?: boolean
  triggerHover?: boolean
}) {
  const { image, title, slug, setHovered } = useContext(PostContext)
  const gatsbyImage = getImage(image) as any
  const imgAlt: string = alt || title || 'Post Image'
  const ref = useRef(null)
  const isHovered = useHoverDirty(ref)
  useEffect(() => {
    if (triggerHover) {
      setHovered(isHovered)
    }
  }, [isHovered])
  if (!isGatsbyImage)
    return (
      <Link to={`/${slug}`} ref={ref} className={wrapperClassName}>
        <img
          srcSet={image.srcSet}
          src={image.src}
          alt={title}
          className={className}
        />
      </Link>
    )
  return (
    <Link to={`/${slug}`} ref={ref} className={wrapperClassName}>
      <GatsbyImage image={gatsbyImage} alt={imgAlt} className={className} />
    </Link>
  )
}

function Date({
  className = 'text-blue-500 date',
  format,
}: {
  className?: string
  format?: string
}) {
  const { date } = useContext(PostContext)
  return (
    <p className={className}>{format ? dayjs(date).format(format) : date}</p>
  )
}

function Title({
  heading = 'heading-3',
  hoverColor = 'text-blue-500',
  triggerHover = true,
}: {
  heading?: string
  hoverColor?: string
  triggerHover?: boolean
}) {
  const { title, slug, hovered, setHovered } = useContext(PostContext)
  const ref = useRef(null)
  const isHovered = useHoverDirty(ref)
  useEffect(() => {
    if (triggerHover) {
      setHovered(isHovered)
    }
  }, [isHovered])
  const titleClass = `${heading} ${hovered ? `${hoverColor}` : ''}`
  return (
    <Link
      ref={ref}
      to={`/${slug}`}
      className={`${titleClass} transition duration-300`}
    >
      {title}
    </Link>
  )
}

function Excerpt({
  limitText = 80,
  strip = true,
  className = 'caption',
}: {
  small?: boolean
  limitText?: number
  strip?: boolean
  className?: string
}) {
  const { excerpt } = useContext(PostContext)
  let text = excerpt || ''
  if (limitText) text = truncate(text, limitText)
  if (strip) text = stripHTML(text)
  return <p className={className}>{text}</p>
}

function Category() {
  const { category } = useContext(PostContext)
  if (!category) return null
  return (
    <Link
      to={`/${getCategoryTypeUrl(category.type)}/${category.slug}`}
      className="text-gray-400 transition subheading-6 lg:heading-6 hover:text-gray-800"
    >
      {category.name}
    </Link>
  )
}

const PostCard = (props: PostCardProps) => {
  const [hovered, setHovered] = useState(false)
  return (
    <PostContext.Provider value={{ ...props, setHovered, hovered }}>
      <div className={`${props.className}`}>{props.children}</div>
    </PostContext.Provider>
  )
}

PostCard.Image = Image
PostCard.Date = Date
PostCard.Title = Title
PostCard.Excerpt = Excerpt
PostCard.Category = Category

export default PostCard
